import React from 'react'
import styled from '@emotion/styled'
import { Container } from 'react-bootstrap'

const Title = styled(Container)`
  background-color: #ee8013;
  font-family: 'Raleway';
  color: white;
  text-transform: uppercase;
  padding: 1rem 3rem;
  margin-bottom: 2rem;

  h2 {
    padding: 0;
    margin: 0;
  }
`

const PageTitle = ({ title }) => (
  <Title>
    <h2>{title}</h2>
  </Title>
)

export default PageTitle
