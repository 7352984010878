import React from 'react'
import styled from '@emotion/styled'
import { Link, graphql } from 'gatsby'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Layout from '../components/layout'
import PageTitle from '../components/page-title'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Truncate from 'react-truncate'
import SEO from '../components/seo'
import { myContext } from '../../provider'

const Service = styled.div`
  background-color: #f8f8f8;
  height: 608px;
  margin-bottom: 3.125rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
`

const ServiceImage = styled.div`
  flex: 1;
  height: 50%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ServiceContent = styled.div`
  display: flex;
  flex: 1;
  color: #4a4a4a;
  padding: 1rem;
  flex-direction: column;
  p {
    flex: 1;
  }
`

const Blog = ({ data }) => {
  const nodes = data.allContentfulBlogPost.nodes

  return (
    <Layout>
      <SEO title="Blog" />
      <myContext.Consumer>
        {({ language }) => {
          return (
            <>
              <PageTitle title="Blog" />
              <Container>
                <Row>
                  {nodes.map((blog) => {
                    return (
                      <Col lg={6}>
                        <Service>
                          <ServiceImage>
                            <img src={blog.image ? blog.image.sizes.src : ''} />
                          </ServiceImage>
                          <ServiceContent>
                            <h4>{blog[`title_${language}`]}</h4>
                            <Truncate lines={3}>
                              {renderRichText(blog[`post_${language}`])}
                            </Truncate>
                            <div>
                              <Link to={`/blog/${blog.slug}`}>
                                <Button>Read More</Button>
                              </Link>
                            </div>
                          </ServiceContent>
                        </Service>
                      </Col>
                    )
                  })}
                </Row>
              </Container>
            </>
          )
        }}
      </myContext.Consumer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPageQuery {
    allContentfulBlogPost {
      nodes {
        title_english
        title_vietnamese
        slug
        image {
          sizes {
            src
          }
        }
        post_english {
          raw
        }
        post_vietnamese {
          raw
        }
      }
    }
  }
`

export default Blog
